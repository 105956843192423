<template>
    <div>

    </div>
</template>

<script>
export default {
    name: 'file-host',
    mounted() {
        window.open(`@public/App-ads.txt`);
    }
}
</script>